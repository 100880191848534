<template>
  <div class="upload-button" v-if="showUploadImageBtn">
    <v-btn
      v-if="disableCropping"
      type="file"
      color="primary"
      text
      class="upload-button__upload"
      :disabled="loading || disabled || isFull"
      @click="selectImage"
    >
      <v-icon class="mr-2">{{ icons.add }}</v-icon>
      {{ label }}
    </v-btn>
    <v-btn
      v-else
      type="file"
      color="primary"
      text
      class="upload-button__upload"
      :disabled="disabled"
      @click="toggle"
    >
      <v-icon class="mr-2">{{ icons.add }}</v-icon>
      {{ label }}
    </v-btn>

    <v-file-input
      v-if="disableCropping"
      v-model="uploaded"
      ref="filePicker"
      multiple
      accept="image/*"
      style="display: none"
      @change="onFileChange"
    />
    <image-cropper
      v-else
      v-model="showUpload"
      :aspect-ratio="aspectRatio"
      :initial-aspect-ratio="initialAspectRatio"
      :disableCropping="disableCropping"
      :maxWidth="maxWidth"
      :maxHeight="maxHeight"
      @change="onChange"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * File Multiple Upload: Upload Button
 * ==================================================================================
 **/

import { mdiPlus } from '@mdi/js'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import FileMultiUploadMixin from '@/utils/mixins/FileMultiUpload'
import ImageCropper from '@/components/modals/ImageCropper'
import ImageCropperMixin from '@/utils/mixins/ImageCropper'
import Compressor from 'compressorjs'
import UPLOAD_IMAGE_SIZE from '@/utils/enums/UploadImageSize'

export default {
  components: {
    ImageCropper,
  },
  mixins: [SnackbarMixin, FileMultiUploadMixin, ImageCropperMixin],
  props: {
    label: {
      type: String,
      default: 'Upload',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
      default: UPLOAD_IMAGE_SIZE.OTHER_IMAGE.width,
    },

    maxHeight: {
      type: Number,
      default: UPLOAD_IMAGE_SIZE.OTHER_IMAGE.height,
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },

    showUploadImageBtn: {
      type: Boolean,
      default: true,
    },

    hasLimit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      uploaded: [],
      errors: [],
      icons: {
        add: mdiPlus,
      },
      showUpload: false,
    }
  },

  computed: {
    hasError() {
      return !!this.errors.length
    },
  },

  methods: {
    selectImage() {
      if (this.$refs.filePicker && this.$refs.filePicker.$refs.input) {
        this.$refs.filePicker.$refs.input.click()
      }
    },

    async onFileChange(e) {
      const images = e

      this.reset()

      let validImages = []
      if (images.length + this.images.length > this.maxImage && this.hasLimit) {
        this.showSnackbar(`Maximum of ${this.maxImage} images only`, false)
      } else {
        for (let i = 0; i < images.length; i++) {
          const file = images[i]
          try {
            // Compress the file using Compressor.js
            const compressedFile = await this.compressFile(file)

            validImages.push(compressedFile)
          } catch (error) {
            console.error('Error compressing file:', error)
          }
        }
        console.log('validImages', validImages)

        if (validImages.length) {
          this.$emit('input', validImages)
        }

        this.addMessage(images, validImages)
        this.reset()
      }
    },

    addMessage(images, validImages) {
      const count = `${validImages.length}/${images.length}`
      const msgHeader = this.forUpdate
        ? `Uploading ${count} image(s)${
            this.hasError ? ', issue(s) encountered:' : '...'
          }`
        : `Added ${count} image(s)`
      const msgColor = validImages.length > 0

      this.showSnackbar(msgHeader, msgColor, this.errors)
    },

    addError(error) {
      if (this.errors.indexOf(error)) {
        this.errors.push(error)
      }
    },

    reset() {
      this.uploaded = []
      this.errors = []
    },

    compressFile(file) {
      return new Promise((resolve, reject) => {
        const instance = new Compressor(file, {
          quality: 0.8, // The quality of the output image
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          width: this.width,
          height: this.height,
          convertTypes: ['image/png', 'image/webp'],
          convertSize: 2 * 1.4 * 1000 * 1000, // If the image over convertSize, it will convert to JPEG type to save the size. The quality 0.8 has compression ratio ~ 46.41% => this.convertSize * 1.5
          success(compressImage) {
            // Create a FileReader to read the data URL from the compressed file
            const reader = new FileReader()
            reader.onload = () => {
              // Resolve the compressed file and its data URL
              resolve({
                file: compressImage,
                url: reader.result, // This is the data URL
              })
            }
            reader.onerror = (error) => {
              reject(error)
            }
            reader.readAsDataURL(compressImage)
          },
          error(err) {
            console.log(err.message)
            reject(err)
          },
        })

        return instance
      })
    },

    base64ToBlob(base64Data) {
      const byteString = atob(base64Data.split(',')[1])
      const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]

      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ab], { type: mimeString })
    },

    onChange(image) {
      let self = this
      if (this.images.length >= this.maxImage) {
        this.showSnackbar(`Maximum of ${this.maxImage} images only`, false)
        return
      }

      const img = new Image()
      img.onload = function () {
        // if (this.width > self.maxWidth) {
        //   self.showSnackbar(
        //     `Ideal image size ${self.maxWidth}px x ${self.maxWidth}px`,
        //     false
        //   )
        // } else {
        self.$emit('input', [
          {
            file: image.file,
            url: image.url,
          },
        ])
        // self.showSnackbar(`Added 1 image`, true, self.errors)
        // }
      }
      img.src = image.url
    },

    toggle() {
      this.showUpload = true
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-button {
  &__upload {
    //
  }
}
</style>
